import { Button, Modal } from "react-bootstrap";
import { useMapitoStore } from "../../zustand/MapitoStore";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { getServerMgr } from "../../managers/ServerMgr";

export default function ModalEditAdministrator(props){
    const show = useMapitoStore((state) => state.showEditAdminModal)
    const setShow = useMapitoStore((state) => state.setShowEditAdminModal)
    const modalInfo = useMapitoStore((state) => state.editAdminModalInfo)
    const updateData = useMapitoStore((state) => state.updateData)
    // const adminList = useMapitoStore((state) => state.administratorsList)

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [telephone, setTelephone] = useState("");
    const [email, setEmail] = useState("");
    const [notes, setNotes] = useState("");

    const [errorSurname, setErrorSurname] = useState(false);

    const handleClose = () => setShow(false, {});
    // const handleShow = () => setShow(true);

    const onClose = () => {   
        handleClose()
    }

    const onConfirm = async () => {
        setErrorSurname(false);
        if(surname === "") {
            setErrorSurname(true);
        } else {
            await getServerMgr().editAdministrator({id: modalInfo.id, name: name, surname: surname, telephone: telephone, email: email, notes: notes}, () => updateData(handleClose))
        }    
    }

    useEffect(() => {
        if(show) {
            setName(modalInfo.name);
            setSurname(modalInfo.surname);
            setTelephone(modalInfo.telephone);
            setEmail(modalInfo.email);
            setNotes(modalInfo.notes);
        }
    }, [show])

    return(
        <Modal
            style={{backgroundColor: "rgba(52, 52, 52, 0.8)"}}
            show={show} 
            onHide={handleClose}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Modifica Amministratore</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>

                    <TextField
                        label="Nome"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    ></TextField>
                    <TextField
                        label="Cognome"
                        error={errorSurname}
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                    ></TextField>
                    <TextField
                        label="Telefono"
                        value={telephone}
                        onChange={(e) => setTelephone(e.target.value)}
                    ></TextField>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    ></TextField>
                    <TextField
                        label="Note"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                    ></TextField>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onConfirm}>
                    {"Conferma"}
                </Button>
                <Button variant="primary" onClick={onClose}>
                    {"Annulla"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}