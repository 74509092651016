import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useMapitoStore } from '../../zustand/MapitoStore';
import AppoRow from './AppoRow';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { ICONS } from '../../assets/icons';

import "./MarkerTable.css"
// function createData(row) {
//   return {row.address, row.appointments, row.marker_notes, row.needWarning, row.administrator}
// }

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const setShowAddAppoModal = useMapitoStore((state) => state.setShowAddAppoModal)
  const setShowDeleteMarkerModal = useMapitoStore((state) => state.setShowDeleteMarkerModal)
  const setShowEditMarkerModal = useMapitoStore((state) => state.setShowEditMarkerModal)

  const calculateNotCompletedAppointments = (appointmentsList) => {
    let notCompletedCount = 0;
    appointmentsList.forEach((item) => {
        if(!item.completed) {notCompletedCount++}
    });
    return notCompletedCount;
  } 

  const getMarkerColor = (appointmentsList) => {
    let completedFlag = false;
    let notCompletedFlag = false;
    let completedIn2025_flag = false;
    let warningFlag = row.needWarning;
    for(let i = 0; i < appointmentsList.length /*|| !(completedFlag && notCompletedFlag)*/; i++){
        completedFlag ||= appointmentsList[i].completed;
        notCompletedFlag ||= !appointmentsList[i].completed;
        completedIn2025_flag ||= parseInt(appointmentsList[i].completion_date) === 2025
    }
    if(completedFlag && notCompletedFlag){
        if(warningFlag) {return ICONS.markerYellow_warning}
        return ICONS.markerYellow
    }
    if(notCompletedFlag){
        if(warningFlag) {return ICONS.markerRed_warning}
        return ICONS.markerRed
    }
    if(completedIn2025_flag){
      if(warningFlag){return ICONS.markerPurple_warning_2025}
      return ICONS.markerPurple_2025
    }
    if(warningFlag) {return ICONS.markerGreen_warning}
    return ICONS.markerGreen
  }

  return (
    <React.Fragment>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } } } /*onClick={()=>{setOpen((prevState) => (!prevState))}}*/>
        <TableCell>
          <div className="markerRow_markerIcon" style={{backgroundImage: getMarkerColor(row.appointments)}} onClick={() => {props.showMarkerOnMap({lat: Number(row.lat), lng: Number(row.lng)})}}></div>            
        </TableCell>
        <TableCell component="th" scope="row" onClick={()=>{setOpen((prevState) => (!prevState))}}>{row.address}</TableCell>
        <TableCell align="right" onClick={()=>{setOpen((prevState) => (!prevState))}}>{row.appointments.length}</TableCell>
        <TableCell align="right" onClick={()=>{setOpen((prevState) => (!prevState))}}>{calculateNotCompletedAppointments(row.appointments)}</TableCell>
        <TableCell align="right" className='hide_mobile' onClick={()=>{setOpen((prevState) => (!prevState))}}>{row.administrator.surname + " " + row.administrator.name}</TableCell>
        <TableCell align="right" className='hide_mobile'>
          <div className='markerRow_button_container'>
            <div className='markerRow_button_edit' style={{backgroundImage: ICONS.edit}} onClick={() => {setShowEditMarkerModal(true, row)}}></div>
            <div className='markerRow_button_delete' style={{backgroundImage: ICONS.delete}} onClick={() => {setShowDeleteMarkerModal(true, row)}}></div>
          </div>
        </TableCell>
        {/* <TableCell align="right">{row.protein}</TableCell> */}
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="markerRow_notesAdmin_container" /*style={{display: open ? "block" : "none"}}*/>
                <div className="markerRow_buttons hide">
                    <div className="markerRow_notes_buttons_edit" onClick={() => {setShowEditMarkerModal(true, row)}}>{"Modifica"}</div>
                    <div className="markerRow_notes_buttons_delete" onClick={() => {setShowDeleteMarkerModal(true, row)}}>{"Elimina"}</div>
                </div>
                <div className="markerRow_notes" style={{display: row.marker_notes ? "block" : "none"}}>
                    <div className="markerRow_notes_text">{"NOTE: " + row.marker_notes}</div>
                </div>
                <div className="markerRow_administrator" /*style={{display: open ? "flex" : "none"}}*/>
                    <div className="flexContainer">
                        <div className="markerRow_administrator_icon" style={{backgroundImage: ICONS.admin_name}}></div>
                        <div className="markerRow_administrator_text">{row.administrator.surname + " " + row.administrator.name}</div>
                    </div>
                    <div className="flexContainer">
                        <div className="markerRow_administrator_icon" style={{backgroundImage: ICONS.admin_telephone}}></div>
                        <div onClick={() => window.location = "tel:" + row.administrator.telephone} className="markerRow_administrator_text hyperlinkText">{row.administrator.telephone}</div>
                    </div>
                    <div className="flexContainer">
                        <div className="markerRow_administrator_icon" style={{backgroundImage: ICONS.admin_email}}></div>
                        <div onClick={() => window.location = "mailto:" + row.administrator.email} className="markerRow_administrator_text hyperlinkText">{row.administrator.email}</div>
                    </div>
                </div>
            </div>
            <Box sx={{ margin: 1 }} style={{padding: "0 20px"}}>
              <div className='flexContainer' style={{margin: "20px 0 30px 0"}}>
                <Typography variant="h6" gutterBottom component="div">
                  Interventi
                </Typography>
                <div style={{width: "20px"}}></div>
                {/* <div className='markerTable_addAppoButton' onClick={() => {setShowAddAppoModal(true, {markerId: row.id})}}>
                  <AddCircleIcon style={{height: "20px"}}/>
                  <div>Aggiungi</div>
                </div> */}
                <Button size="small" variant="contained" startIcon={<AddCircleIcon />} onClick={() => {setShowAddAppoModal(true, {markerId: row.id})}} style={{height: "30px"}}>
                  Aggiungi
                </Button>
              </div>
              {
              row.appointments.length !== 0
              ?
              row.appointments.map((item) => (
                <AppoRow key={item.appointment_id} appo={item}></AppoRow> 
              ))
              :
              <>Nessun intervento</>
              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#123456",//theme.palette.grey[500],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function MarkerTable({show, showMarkerOnMap}) {

  const markersList = useMapitoStore((store) => store.markersList)
  const filterMarker = useMapitoStore((state) => state.filterMarker) 
  const markerFilters = useMapitoStore((state) => state.markerFilters) 
  const sortMarker = useMapitoStore((state) => state.sortMarker)
  const markerSortBy = useMapitoStore((state) => state.markerSortBy) 
  const markerSortingType = useMapitoStore((state) => state.markerSortingType) 
  

  return (
    <TableContainer component={Paper} sx={{ maxHeight: "calc(100vh - 56px - 56px - 60px)", height: "100%" }} style={{display: show ? "block" : "none"}}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead style={{backgroundColor: "black"}}>
          <TableRow >
            <StyledTableCell style={{minWidth: "70"}}></StyledTableCell>
            <StyledTableCell style={{minWidth: "270"}}>Indirizzo</StyledTableCell>
            <StyledTableCell align="right" style={{minWidth: "70"}}>Tot.</StyledTableCell>
            <StyledTableCell align="right" style={{minWidth: "70"}}>Inc.</StyledTableCell>
            <StyledTableCell align="right" className={"hide_mobile"} style={{minWidth: "150"}}>Amministratore</StyledTableCell>
            <StyledTableCell align="right" className={"hide_mobile"} style={{minWidth: "70"}}></StyledTableCell>
            {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
          </TableRow >
        </TableHead>
        <TableBody>
          {//Object.keys(markersList).map((key) => {      
            Object.keys(markersList).map((markerKey) => (markersList[markerKey])).sort(sortMarker).map((row) => {      
            // let item = markersList[key]
            // let row = markersList[key]
            if(filterMarker(row, markerFilters)) {
              return (
                // <MarkerRow item={markersList[key]} showMarkerOnMap={props.showMarkerOnMap} onClickDelete={props.onClickDelete} onClickEdit={props.onClickEdit}></MarkerRow>
                <Row key={row.id} row={row} showMarkerOnMap={showMarkerOnMap} toRefreshSortBy={markerSortBy} toRefreshSortType={markerSortingType}/> //TODO: invece di usasre filterMarker in un if metterlo in uno stile che toglie o mette il display della Row
              )
            } else {
              return null;
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}