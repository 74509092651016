import { Badge, Button, Modal } from "react-bootstrap";
import { useMapitoStore } from "../../zustand/MapitoStore";
import { useEffect, useState } from "react";
import { getServerMgr } from "../../managers/ServerMgr";

export default function ModalResetAdminMarkers(){
    const show = useMapitoStore((state) => state.showResetMarkersForAdminModal)
    const setShow = useMapitoStore((state) => state.setShowResetMarkersForAdminModal)
    const modalInfo = useMapitoStore((state) => state.resetMarkersForAdminModalInfo)
    const updateData = useMapitoStore((state) => state.updateData)

    const [adminSurname, setAdminSurname] = useState("");
    const [adminName, setAdminName] = useState("");

    const handleClose = () => setShow(false);

    const onClose = () => {   
            handleClose()
        }
    
    const onConfirm = async () => {
        // setErrorSurname(false);
        // if(surname === "") {
        //     setErrorSurname(true);
        // } else {
        //     await getServerMgr().editAdministrator({id: modalInfo.id, name: name, surname: surname, telephone: telephone, email: email, notes: notes}, () => updateData(handleClose))
        // }    
        await getServerMgr().resetMarkersForAdmin({adminId: modalInfo.id, date: ""}, () => updateData(handleClose))
    }

    useEffect(() => {
        if(show) {
            setAdminSurname(modalInfo.surname);
            setAdminName(modalInfo.name);
        }
    }, [show])

    return(
        <Modal
            style={{backgroundColor: "rgba(52, 52, 52, 0.8)"}}
            show={show} 
            onHide={handleClose}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Conferma reset interventi</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div>
                    Vuoi davvero resettare i marker dell'amministratore <b>{adminName}  {adminSurname}</b>?
                    <br/>
                    <br/>
                    Se confermi la scelta per ogni intervento di ciascun marker di questo amministratore verranno annullate 
                    <b> tutte le date di completamento e di programmazione</b> inserite.
                </div>
                <br/>
                <div> <Badge bg="danger">ATTENZIONE:</Badge><b> QUESTA AZIONE E' IRREVERSIBILE</b></div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onConfirm}>
                    {"Conferma"}
                </Button>
                <Button variant="primary" onClick={onClose}>
                    {"Annulla"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}