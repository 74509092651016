export const IMGNOURL = {    
    //NO URL
    aooo : "./icons/aooo.png",
    markerRed: "./icons/icon_markerRed.png",
    markerYellow: "./icons/icon_markerYellow.png",
    markerGreen: "./icons/icon_markerGreen.png",
    markerPurple_2025: "./icons/icon_markerPurple_2025.png",
    markerRed_warning: "./icons/icon_markerRed_warning.png",
    markerYellow_warning: "./icons/icon_markerYellow_warning.png",
    markerGreen_warning: "./icons/icon_markerGreen_warning.png",
    markerPurple_warning_2025: "./icons/icon_markerPurple_warning_2025.png",
    markerAdd: "./icons/icon_markerAdd.png",
    appoComplete: "./icons/completed_icon.png",
    appoDecomplete: "./icons/cancel_icon.png",
    appoCompleteLabel: "./images/label_complete.png",
    appoNotCompleteLabel: "./images/label_notComplete.png",
}