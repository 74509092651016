import { getServerMgr } from "../managers/ServerMgr"
import XLSX from "xlsx";
import { saveAs } from 'file-saver';
import { Button } from "react-bootstrap";
import GetAppIcon from '@mui/icons-material/GetApp';
import ListIcon from '@mui/icons-material/People';
import "./SettingsPage.css"
import { Slider, Stack } from "@mui/material";
import { ZoomIn, ZoomOut } from "@mui/icons-material";
import { useState } from "react";
import ModalAdministratorsList from "../ui/modal/ModalAdministratorsList";
import { useMapitoStore } from "../zustand/MapitoStore";

export default function SettingsPage(props) {

    const setModal = useMapitoStore((state) => state.setShowAdminListModal)

    const serverMgr = getServerMgr()

    const getCompleted = async () => {
        let list = await serverMgr.getCompleted()
        console.log("LIST: ", list)
        
        const content = {"hum_in": list
        // [
        //   {"ts":1646034284215,"value":"22"},
        //   {"ts":1646033983313,"value":"22"}
        // ]
        };

        const worksheet = XLSX.utils.json_to_sheet(content.hum_in);
        
        function generateAsExcel(data) {
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const EXCEL_EXTENSION = '.xlsx';

          try {
            const workbook = XLSX.utils.book_new();
            
            for (let key in data) {
              const worksheet = XLSX.utils.json_to_sheet(data[key]);
              XLSX.utils.book_append_sheet(workbook, worksheet, key);
            }
            
            let res = XLSX.write(workbook, { type: "array" });
            console.log(`${res.byteLength} bytes generated`);
            const fileData = new Blob([res], { type: EXCEL_TYPE });
            saveAs(fileData, "Export_" + new Date().getTime() + EXCEL_EXTENSION);
          } catch (err) {
            console.log("Error:", err);
          }
        }
        
        generateAsExcel(content);
    }

    const mostraListaAmministratori = () => {
      // await getServerMgr().getAdministratorsList();
      setModal(true)
      // console.log(lista)
    }

    return (        
        <div style={{display: props.show ? "block" : "none"}}>
            <div className="settingsPage_title">
                <div>{"Impostazioni"}</div>
            </div>
            <div className="settingsPage_container"> 
                {/* <div className="settingsPage_subtitle">Impostazioni mappa</div>    
                <div className="settingsPage_info_container">    
                  <div>
                    <div className="settingsPage_info_title">Zoom default</div>
                  </div> 
                  <div style={{width: "300px"}}>                    
                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                      <ZoomIn />
                      <Slider defaultValue={8} step={1} marks min={3} max={15} />  
                      <ZoomOut />
                    </Stack> 
                  </div>                 
                </div>     */}
                <div className="settingsPage_subtitle">Download documenti</div>   
                <div className="settingsPage_info_container">    
                  <div>
                    <div className="settingsPage_info_title">Interventi completati</div>
                    <div className="settingsPage_info_subtitle">Excel contenente la lista degli interventi completati</div>
                  </div>    
                  <div onClick={getCompleted} className="settingsPage_info_button">
                      <GetAppIcon/>{"Scarica"}
                  </div>                  
                </div>    
                <div className="settingsPage_subtitle">Amministratori</div>   
                <div className="settingsPage_info_container">    
                  <div>
                    <div className="settingsPage_info_title">Gestione amministratori</div>
                    <div className="settingsPage_info_subtitle">Lista di amministratori con dettagli di contatto e relativi condomini</div>
                  </div>    
                  <div onClick={mostraListaAmministratori} className="settingsPage_administrators_button">
                      <ListIcon/>{"Lista"}
                  </div>                  
                </div>    
            </div>
        </div>
    )
}