import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useMapitoStore } from '../../zustand/MapitoStore';
import EditMarkerModal from '../../markerList/EditMarkerModal';
import DeleteMarkerModal from '../../markerList/DeleteMarkerModal';
import CompleteAppoModal from '../../modal/CompleteAppoModal';
import DeleteAppoModal from '../../modal/DeleteAppoModal';
import EditAppoModal from '../../modal/EditAppoModal';
import { getServerMgr } from '../../managers/ServerMgr';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { getUtils } from '../../managers/Utils';
import { Checkbox, FormControlLabel, MenuItem, Select, TextField } from '@mui/material';
import BasicSelect from '../input/select/BasicSelect';

import "./ModalAddAppo.css"
import CustomCarousel from '../carousel/CustomCarousel';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { CheckBox } from '@mui/icons-material';

export default function ModalEditMarker(props) {
    const show = useMapitoStore((state) => state.showEditMarkerModal)
    const setShow = useMapitoStore((state) => state.setShowEditMarkerModal)
    const modalInfo = useMapitoStore((state) => state.editMarkerModalInfo)
    const updateData = useMapitoStore((state) => state.updateData)
    const adminList = useMapitoStore((state) => state.administratorsList)
    const appointmentTypes = useMapitoStore((state) => state.appointmentTypes)

    //STATE
    const [markerAddress, setMarkerAddress] = useState("")
    const [markerNotes, setMarkerNotes] = useState("")
    const [needWarning, setNeedWarning] = useState(false)
    const [isNewAdmin, setIsNewAdmin] = useState(false)
    const [adminId, setAdminId] = useState(0);
    const [adminSurname, setAdminSurname] = useState("")
    const [adminName, setAdminName] = useState("")
    const [adminTel, setAdminTel] = useState("")
    const [adminEmail, setAdminEmail] = useState("")

    const [showAddressError, setShowAddressError] = useState(false)
    const [showSurnameError, setShowSurnameError] = useState(false)
    

    //USEEFFECT
    useEffect(() => {
        if(show) {
            setMarkerAddress(modalInfo.address)
            setMarkerNotes(modalInfo.marker_notes)
            setNeedWarning(modalInfo.needWarning)
            setAdminId(modalInfo.administrator.admin_id)
            setAdminSurname(modalInfo.administrator.surname)
            setAdminName(modalInfo.administrator.name)
            setAdminTel(modalInfo.administrator.telephone)
            setAdminEmail(modalInfo.administrator.email)
        }
    }, [show])

    const handleClose = () => setShow(false, {});
    const handleShow = () => setShow(true);

    const onClose = () => {   
        handleClose()
        setIsNewAdmin(false);
    }

    const onConfirm = async (id) => {
        setShowAddressError(false)
        setShowSurnameError(false)
        if(markerAddress === "" || adminSurname === "") {
            if(markerAddress === "") {
                setShowAddressError(true)
            }
            if(adminSurname === "") {
                setShowSurnameError(true)
            }
        } else {
            //let newTimestamp = getUtils().addTimeStamp(date, time)
            await getServerMgr().editMarker({markerId: modalInfo.id, markerName: markerAddress, needWarning: true/*TODO*/, markerNotes: markerNotes, needWarning: needWarning, adminId: adminId, adminSurname: adminSurname, adminName: adminName, adminTel: adminTel, adminEmail: adminEmail}, () => updateData(handleClose))
            // setAddAppointmentFormList([])
            // updateData()
        }        
    }

    const switchAdminType = () => {
        if(!isNewAdmin){
            setAdminId(0)
            setAdminSurname("")
            setAdminName("")
            setAdminTel("")
            setAdminEmail("")
        }
        else{
            setAdminId(modalInfo.administrator.admin_id)
            setAdminSurname(modalInfo.administrator.surname)
            setAdminName(modalInfo.administrator.name)
            setAdminTel(modalInfo.administrator.telephone)
            setAdminEmail(modalInfo.administrator.email)
        }
        setIsNewAdmin(!isNewAdmin)
        console.log(adminList)
    }

    return (
        <>
        <Modal 
            show={show} 
            onHide={onClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {"Modifica marker"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div>
                <h6 style={{marginBottom: "20px"}}>{"Modifica marker:"}</h6>
                <div className='modalAddAppo_bodyContainer'>
                    <div style={{width: "80%"}}> 
                        <div className='flexContainer' /*style={{justifyContent: "space-between"}}*/>
                            <h5 style={{marginRight: "20px"}}>Marker</h5> 
                            <FormControlLabel
                                sx={{display: "flex", justifyContent: "space-between", height: "24px"}}
                                value={"AVVISO"}
                                control={<Checkbox onChange={() => {setNeedWarning((prevState) => !prevState)}} checked={needWarning} />}
                                label={"AVVISO"}
                                labelPlacement="end"
                            />
                        </div>        
                        <TextField 
                            label={"Indirizzo"} 
                            value={markerAddress} 
                            onChange={(e) => {setMarkerAddress(e.target.value); setShowAddressError(false)}}
                            error={showAddressError}
                            helperText={showAddressError ? "Inserire indirizzo" : ""}
                        />             
                        <div style={{display: "flex", justifyContent: "center", marginTop: "12px"}}>                        
                            <TextField
                                style={{width: "100%", marginLeft: "8px"}}
                                id="outlined-multiline-static"
                                label="Note"
                                multiline
                                value={markerNotes}
                                onChange={(e) => setMarkerNotes(e.target.value)}
                            />
                        </div>   
                        <h5>Amministratore <Button onClick={switchAdminType}>{isNewAdmin ? "Esistente" : "Nuovo"}</Button></h5>             
                        
                        {!isNewAdmin &&
                            <div>
                                <Select style={{width: "100%"}} value={String(adminId)} onChange={(e) => setAdminId(Number(e.target.value))}>
                                    {/* <MenuItem hidden value={adminSurname + "" + adminName}></MenuItem> */}
                                    {Object.keys(adminList).map((adminKey) => adminList[adminKey]).sort((a, b) => a.surname.localeCompare(b.surname)).map((admin) =>     
                                        <MenuItem key={admin.id} value={admin.id}>{admin.surname} {admin.name}</MenuItem>
                                    )}
                                </Select>

                                <div>
                                    <div className='modalEditMarker_detailsTitle'>COGNOME:</div>
                                    <div>{adminSurname}</div>
                                    <div className='modalEditMarker_detailsTitle'>NOME:</div>
                                    <div>{adminName}</div>
                                    <div className='modalEditMarker_detailsTitle'>TELEFONO: </div>
                                    <div>{adminTel}</div>
                                    <div className='modalEditMarker_detailsTitle'>EMAIL:</div>
                                    <div>{adminEmail}</div>
                                </div>
                            </div>
                            
                        }

                        {isNewAdmin &&
                        <>
                            <div className='modalAddAppo_dateTimeContainer'>
                                <TextField 
                                    label={"Cognome"} 
                                    value={adminSurname} 
                                    onChange={(e) => {setAdminSurname(e.target.value); setShowSurnameError(false)}}
                                    error={showSurnameError}
                                    helperText={showSurnameError ? "Inserire cognome" : ""}
                                />
                                <TextField label={"Nome"} value={adminName} onChange={(e) => setAdminName(e.target.value)}/>
                            </div>                
                            <div className='modalAddAppo_dateTimeContainer'>
                                <TextField label={"Telefono"} value={adminTel} onChange={(e) => setAdminTel(e.target.value)}/>
                                <TextField label={"Email"} value={adminEmail} onChange={(e) => setAdminEmail(e.target.value)}/>
                            </div>
                        </>
                        }                    
                    </div>
                </div>
            </div>    
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onConfirm}>
                    {"Conferma"}
                </Button>
                <Button variant="primary" onClick={onClose}>
                    {"Annulla"}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}
