import { AdvancedMarker, Pin } from "@vis.gl/react-google-maps";
import { COLORS } from "../assets/colors";
import { ICONS } from "../assets/icons";
import { IMGNOURL } from "../assets/imgNoUrl";
import "./GenericCustomMarker.css"

export default function GenericCustomMarker(props) {

    const getMarkerColor = (appointmentsList) => {
        let completedFlag = false;
        let notCompletedFlag = false;
        let completedIn2025_flag = false;
        let warningFlag = props.info.needWarning;
        for(let i = 0; i < appointmentsList.length /*|| !(completedFlag && notCompletedFlag)*/; i++){
            completedFlag ||= appointmentsList[i].completed;
            notCompletedFlag ||= !appointmentsList[i].completed;
            completedIn2025_flag ||= parseInt(appointmentsList[i].completion_date) === 2025
            // console.log(appointmentsList[i].completion_date)
        }
        if(completedFlag && notCompletedFlag){
            if(warningFlag) {return IMGNOURL.markerYellow_warning}
            return IMGNOURL.markerYellow
        }
        if(notCompletedFlag){
            if(warningFlag) {return IMGNOURL.markerRed_warning}
            return IMGNOURL.markerRed
        }
        if(completedIn2025_flag){
            if(warningFlag){return IMGNOURL.markerPurple_warning_2025}
            return IMGNOURL.markerPurple_2025
        }
        if(warningFlag) {return IMGNOURL.markerGreen_warning}
        return IMGNOURL.markerGreen
    }

    return(
        <AdvancedMarker position={props.position} onClick={(e) => {props.onClick(e, props.info)}}>
            {/* <Pin background={getMarkerColor(props.appointments)} glyphColor={'#000'} borderColor={'#000'} /> */}
            <img className="markerSize" src={getMarkerColor(props.appointments)}></img>
        </AdvancedMarker>
    )
}