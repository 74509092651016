export const ICONS = {
    map: `url("./icons/map_icon.png")`,
    markerList: `url("./icons/markerList_icon.png")`,
    settings: `url("./icons/settings_icon.png")`,
    map_grad: `url("./icons/icon_map.png")`,
    markerList_grad: `url("./icons/icon_list.png")`,
    settings_grad: `url("./icons/icon_settings.png")`,
    closeSquare: `url("./icons/closeSquare_ icon.png")`,
    filters: `url("./icons/filter_icon.png")`,
    filters_black: `url("./icons/filter_icon_black.png")`,
    sorting_black: `url("./icons/sorting_icon_black.png")`,
    info: `url("./icons/info_icon.png")`,
    logoBanner: `url("./logo/logo_banner_noBackground.png")`,
    edit: `url("./icons/icon_edit.png")`,
    delete: `url("./icons/icon_delete.png")`,
    markerRed: `url("./icons/icon_markerRed.png")`,
    markerYellow: `url("./icons/icon_markerYellow.png")`,
    markerGreen: `url("./icons/icon_markerGreen.png")`,
    markerPurple_2025: `url("./icons/icon_markerPurple_2025.png")`,
    markerRed_warning: "url(./icons/icon_markerRed_warning.png)",
    markerYellow_warning: "url(./icons/icon_markerYellow_warning.png)",
    markerGreen_warning: "url(./icons/icon_markerGreen_warning.png)",
    markerPurple_warning_2025: "url(./icons/icon_markerPurple_warning_2025.png)",
    admin_name: `url("./icons/admin_name.png")`,
    admin_telephone: `url("./icons/admin_telephone.png")`,
    admin_email: `url("./icons/admin_email.png")`,
    admin_notes: `url("./icons/admin_notes.png")`,
    dot_green: `url("./icons/dot_completed.png")`,
    dot_red: `url("./icons/dot_incomplete.png")`,
    hamburger: `url("./icons/hamburger_menu.png")`,
    search_map: `url("./icons/searchMap_icon.png")`,
    tab_marker: `url("./icons/markerList_marker_icon.png")`,
    tab_admin: `url("./icons/markerList_admin_icon.png")`,
    tab_appo: `url("./icons/markerList_appo_icon.png")`,
    appoCompleteDark: `url("./icons/completed_icon_dark.png")`,
    appoCompleteDarkDisabled: `url("./icons/completed_icon_dark_disabled.png")`,

}