import { useState } from "react";
import { Accordion, Button, Col, Collapse, Modal, Row } from "react-bootstrap";
import { useMapitoStore } from "../../zustand/MapitoStore";
import "./ModalAdministratorsList.css";

export default function ModalAdministratorsList() {
    const show = useMapitoStore((state) => state.showAdminListModal)
    const setShow = useMapitoStore((state) => state.setShowAdminListModal)
    // const modalInfo = useMapitoStore((state) => state.adminListModalInfo)
    const adminList = useMapitoStore((state) => state.administratorsList)

    const [open, setOpen] = useState("");
    const handleClose = () => setShow(false);
    
    const setShowEditAdminModal = useMapitoStore((state) => state.setShowEditAdminModal)
    const setShowResetMarkersForAdminModal = useMapitoStore((state) => state.setShowResetMarkersForAdminModal)

    return (
        <Modal
            show={show} 
            onHide={handleClose}
            fullscreen
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Lista Amministratori</Modal.Title>
            </Modal.Header>
            <Row className="table_header">
                <Col className="column_table_header">Cognome</Col>
                <Col className="column_table_header">Nome</Col>
                <Col className="column_table_header hide_in_mobile">Telefono</Col>
                <Col className="column_table_header hide_in_mobile">Email</Col>
                {/* <Col className="column_table_header hide_in_mobile">Note</Col> */}
                <Col className="column_table_header">Reset</Col>
                <Col className="column_table_header">Modifica</Col>
            </Row>
            <Modal.Body className="modal_body">
                <div style={{width: "100%"}}>
                    
                
                    {Object.keys(adminList).map((adminKey) => adminList[adminKey]).sort((a, b) => a.surname.localeCompare(b.surname)).map((admin) => 
                        <div style={{borderBottom: "1px solid darkblue"}}>
                            <Row className="admin_row">
                                <Col className="admin_col">{admin.surname}</Col>
                                <Col className="admin_col">{admin.name}</Col>
                                <Col className="admin_col hide_in_mobile">{admin.telephone}</Col>
                                <Col className="admin_col hide_in_mobile">{admin.email}</Col>
                                {/* <Col className="admin_col hide_in_mobile">{admin.notes}</Col> */}
                                <Col className="admin_col">
                                    <Button onClick={() => {
                                        setShowResetMarkersForAdminModal(true, admin)
                                    }}>Reset</Button>
                                </Col>    
                                <Col className="admin_col">
                                    <Button variant="danger" onClick={() => {
                                        setShowEditAdminModal(true, admin)
                                    }}>Modifica</Button>
                                </Col>    
                            </Row>
                            
                            <div style={{marginBottom: "2px", paddingLeft: "10px"}}>
                                <Button id={admin.id} onClick={(e) => {
                                    if(admin.id === open){
                                        setOpen("")
                                    }
                                    else{
                                        setOpen(e.target.id)
                                    }
                                }
                                }>
                                    {admin.id === open ? "Chiudi" : "Mostra"}
                                </Button>
                            </div>

                            <div>
                            <Collapse in={admin.id === open}>
                                <div>
                                {admin.markers.map((marker) => 
                                    <div style={{borderBottom: "1px solid blue"}}>
                                        <Row className="address_row">{marker.address}</Row>
                                        {/* <Row style={{paddingRight: "0px", paddingLeft: "10px", margin: "0px"}}>{marker.address}</Row> */}
                                        <Row style={{margin: "0px"}}>
                                            <Col className="details_col details_col_header">INTERVENTI</Col>
                                            <Col className="details_col details_col_header">EFFETTUATO IL</Col>
                                        </Row>
                                        
                                        {marker.appointments.map((appointment) => 
                                            <Row style={{margin: "0px"}}>
                                                <Col className="details_col">- {appointment.type_name}</Col>
                                                <Col className="details_col">{appointment.completion_date ? appointment.completion_date : "da fare"}</Col>
                                            </Row>
                                        )}
                                    </div>
                                )}
                                </div>
                            </Collapse>
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}